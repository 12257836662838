import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css } from 'emotion';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import { breakup } from '../config/breakpoints';
import { parseNetlifyUrl } from '../utils';
import { themeVars } from '../config/styles';

import Button from '../components/Common/ButtonDark';
import ButtonShare from '../components/Common/ButtonShare';
import LayoutPage from '../components/Layouts/LayoutPage';
import ButtonList from '../components/Common/ButtonList';

export const pageQuery = graphql`
  query($id: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }

    wordpressPost(wordpress_id: { eq: $id }, status: { eq: "publish" }) {
      yoast {
        title
        metadesc
        linkdex
        metakeywords
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        canonical
        redirect
        opengraph_title
        opengraph_description
        opengraph_image
        twitter_title
        twitter_description
      }

      link
      title
      content
      date
      acf {
        page_hero_title
        page_hero_subtitle
        page_hero_bg {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 75, toFormat: JPG) {
                tracedSVG
                sizes
                src
                srcSet
                aspectRatio
              }
            }
          }
        }
      }
    }

    wordpressAcfOptions {
      options {
        options_rescue {
          panel_one_title
          panel_one_label
          panel_one_page {
            post_name
          }
          panel_two_title
          panel_two_label
          panel_two_page {
            post_name
          }
        }
      }
    }
  }
`;

const Post = ({ data, location }) => {
  // Scroll to the top of the page on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const postMarkup = { __html: parseNetlifyUrl(data.wordpressPost.content) };

  const cssWrapper = css`
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;

  const cssArticle = css`
    margin-bottom: 6rem;

    ul,
    ol {
      margin-bottom: 2rem;
    }

    blockquote {
      border-left: 2px solid ${themeVars.root.secondary};
      padding-left: 2rem;
    }
  `;

  return (
    <LayoutPage
      data={data.wordpressPost}
      optionsData={data.wordpressAcfOptions}
      siteMeta={data.site}
    >
      <Container className={cssWrapper} fluid>
        <Row>
          <Col xs={12} md={10} lg={8} push={{ md: 1, lg: 2 }}>
            <article dangerouslySetInnerHTML={postMarkup} className={cssArticle} />

            <ButtonList>
              <li>
                <Link to="/news/">
                  <Button>Back to news</Button>
                </Link>
              </li>
              <li>
                <ButtonShare location={location} />
              </li>
            </ButtonList>
          </Col>
        </Row>
      </Container>
    </LayoutPage>
  );
};

export default Post;

Post.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.isRequired,
};
