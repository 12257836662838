import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { css } from 'emotion';

import { breakup } from '../../config/breakpoints';

import ButtonPrimary from './ButtonPrimary';

// TODO: This should be converted to functional component and optimised

const styles = (theme) => ({
  typography: {
    margin: theme.spacing.unit * 3,
  },
  root: {
    padding: '3rem',
  },
});

class ButtonShare extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, location } = this.props;

    const open = Boolean(anchorEl);

    const cssShareButton = css`
      ${breakup.large`display: flex;`}

      .SocialMediaShareButton {
        display: inline-block;
        cursor: pointer;
        margin-right: 1rem;

        ${breakup.large`margin-right: 1.5rem;`}

        &:last-child {
          margin-right: 0;
        }
      }
    `;

    return (
      <div>
        <ButtonPrimary
          aria-owns={open ? 'share' : undefined}
          aria-haspopup="true"
          variant="contained"
          onClick={this.handleClick}
        >
          Share
        </ButtonPrimary>
        <Popover
          id="share"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.root}>
            <div className={cssShareButton}>
              <TwitterShareButton url={location.href}>
                <TwitterIcon size={24} />
              </TwitterShareButton>
              <LinkedinShareButton url={location.href}>
                <LinkedinIcon size={24} />
              </LinkedinShareButton>
              <FacebookShareButton url={location.href}>
                <FacebookIcon size={24} />
              </FacebookShareButton>
              <WhatsappShareButton url={location.href}>
                <WhatsappIcon size={24} />
              </WhatsappShareButton>
              <EmailShareButton url={location.href}>
                <EmailIcon size={24} />
              </EmailShareButton>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

ButtonShare.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ButtonShare);
