import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { darken } from 'polished';

import { themeVars } from '../../config/styles';

const ButtonPrimary = (props) => {
  const { classes, children } = props;

  return (
    <Button {...props} className={classes.root}>
      {children}
    </Button>
  );
};

// MUI Overrides
const styles = {
  root: {
    borderRadius: 0,
    borderWidth: 3,
    fontFamily: themeVars.root.headerFontFamily,
    backgroundColor: themeVars.root.primary,
    fontSize: '1.6rem',
    borderColor: themeVars.root.primary,
    color: themeVars.root.light,
    padding: '0 30px',
    height: '50px',
    fontWeight: 600,
    textTransform: 'none',

    '&:hover': {
      backgroundColor: darken('0.05', themeVars.root.primary),
      color: themeVars.root.light,
    },
  },
};

ButtonPrimary.defaultProps = {
  variant: 'outlined',
};

ButtonPrimary.propTypes = {
  /** Material UI classes for style overrides */
  classes: PropTypes.shape({}).isRequired,

  /** The label for the button */
  children: PropTypes.string.isRequired,

  /** The MUI button type */
  variant: PropTypes.string,
};

export default withStyles(styles)(ButtonPrimary);
