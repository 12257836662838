import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const ButtonList = ({ children }) => {
  const cssButtonList = css`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    & > li {
      margin-right: 15px;
    }
  `;
  return <ul className={cssButtonList}>{children}</ul>;
};

ButtonList.propTypes = {
  /** A list of Button components */
  children: PropTypes.node.isRequired,
};

export default ButtonList;
